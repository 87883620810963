import React, { Component } from 'react'
import {
	Timeline,
	Modal,
	Alert,
	DatePicker,
	Switch,
	Row,
	Col,
	Divider
} from 'antd'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment-timezone'

import Loading from '../../components/LoadingPage'
import * as Utils from '../../components/Utils'
import S, { Colors } from '../../components/Styles'
import {
	normalizeTimezoneToISOFormat,
	convertTimezoneISOToUTCFormat
} from '../../utils/date'
import config from '../../config'

class SelectProgram extends Component {
	state = {
		error: null,
		// selected date after the timezone conversion
		date: null,
		// selected date before any timezone conversion
		rawDate: null,
		isDeviceTimeZone: false
	}

	handleCancel = () => {
		this.props.onClose()
	}

	updatePickedTime = () => {
		if (this.state.rawDate) {
			// pass the selected date before any timezone conversion
			this.pickedTime(this.state.rawDate)
		}
	}

	pickedTime = (value) => {
		// if the timezone displayed to the user is the device's timezone
		// then transpose the picked date to reflect the device timezone offset
		if (this.state.isDeviceTimeZone) {
			// We have to get the device's offset
			const offset = moment(value)
				.tz(normalizeTimezoneToISOFormat(this.props.device.timeZone))
				.utcOffset()
			// Then we can subtract this offset to the selected date to get a date transposed to match the device timezone
			const dateWithTimezoneOffset = new Date(
				moment(value).toDate().getTime() - offset * 60 * 1000
			)
			this.setState({ date: dateWithTimezoneOffset, rawDate: value })
		} else {
			this.setState({ date: value, rawDate: value })
		}
	}

	render() {
		const { device, zone, intl } = this.props
		const isDeviceTimeZone = this.state.isDeviceTimeZone

		const normalizedDeviceTimezone = normalizeTimezoneToISOFormat(
			device.timeZone
		)
		const isDifferentTimezone = moment.tz.guess() !== device.timeZone

		let vars = { uuid: device.uuid }
		if (this.state.date) {
			vars.date = this.state.date
		}
		if (zone) {
			vars.zone = zone._id
		}

		return (
			<Modal
				visible={true}
				title={intl.formatMessage(
					{ id: 'history.devicePlaybackHistory' },
					{ name: device.nickname || device.name || '' }
				)}
				onCancel={this.handleCancel}
				footer={[]}
			>
				<Row style={S.centered} align="middle">
					<Col span="12" style={S.centered}>
						<DatePicker
							showTime
							format="DD-MM-YYYY HH:mm:ss"
							placeholder={intl.formatMessage({
								id: 'history.selectTime'
							})}
							onChange={this.pickedTime}
						/>
					</Col>
					<Col span="12" style={S.centered}>
						{isDifferentTimezone && (
							<Switch
								style={S.switchOneColor}
								checkedChildren={
									intl.formatMessage({
										id: 'history.deviceTimezone'
									}) +
									`: ${convertTimezoneISOToUTCFormat(normalizedDeviceTimezone)}`
								}
								unCheckedChildren={
									intl.formatMessage({
										id: 'history.localTimezone'
									}) +
									`: ${convertTimezoneISOToUTCFormat(moment.tz.guess())}`
								}
								onChange={(value) => {
									this.setState(
										{
											isDeviceTimeZone: value
										},
										() => {
											this.updatePickedTime()
										}
									)
								}}
								checked={this.state.isDeviceTimeZone}
							/>
						)}
					</Col>
				</Row>
				<Divider />
				<Query
					variables={vars}
					query={gql`
						query fetchDevicePlayHistory(
							$uuid: String!
							$date: Date
							$zone: String
							$evtType: Float
						) {
							histories(
								deviceUuid: $uuid
								date: $date
								zoneId: $zone
								event: $evtType
							) {
								deviceUuid
								trackId
								programId
								playlistId
								manualPlaylist
								zoneId
								date
								event
								message
								username
								messageId
							}
						}
					`}
					pollInterval={config.longPollInterval}
				>
					{({ loading, error, data }) => {
						if (loading) {
							return <Loading />
						}
						if (error) {
							return (
								<Alert
									message={intl.formatMessage({
										id: 'error'
									})}
									description={Utils.displayGraphQLErrors(
										error
									)}
									type="error"
									showIcon
								/>
							)
						}

						if (!data.histories || data.histories.length === 0) {
							return (
								<div style={styles.noData}>
									<FormattedMessage id="history.noData" />
								</div>
							)
						}

						this.zones = {}
						for (let z in device.zones) {
							const zone = device.zones[z]
							this.zones[zone._id] =
								`#${parseInt(z, 10) + 1} ${zone.name}`
						}

						return (
							<Timeline>
								{data.histories.map((h, i) => {
									const hd = Utils.historyDisplay(
										intl,
										h,
										device
									)
									if (!hd) {
										return null
									}
									return (
										<Timeline.Item
											dot={
												hd.icon && (
													<FontAwesomeIcon
														icon={hd.icon}
														style={{
															fontSize: '14px'
														}}
														color={hd.color}
													/>
												)
											}
											key={`${i}`}
										>
											<div style={styles.eventContainer}>
												<div style={styles.eventTitle}>
													{hd.contents}
												</div>
												{hd.details}
												<div style={styles.time}>
													<FormattedDate
														hour="2-digit"
														minute="2-digit"
														hour12={false}
														weekday="short"
														day="numeric"
														month="short"
														value={h.date}
														// We need to normalize the timezone here because this component expects the ISO format
														timeZone={
															isDeviceTimeZone ?
																normalizedDeviceTimezone
															:	undefined
														}
													/>
													{h.zoneId && (
														<React.Fragment>
															<Divider type="vertical" />
															{this.zones[
																h.zoneId
															] || h.zoneId}
														</React.Fragment>
													)}
													{h.username && (
														<React.Fragment>
															<Divider type="vertical" />
															{h.username}
														</React.Fragment>
													)}
												</div>
											</div>
										</Timeline.Item>
									)
								})}
							</Timeline>
						)
					}}
				</Query>
			</Modal>
		)
	}
}

const styles = {
	noData: {
		position: 'relative',
		padding: '16px 16px',
		background: '#fff',
		textAlign: 'center',
		fontSize: '14px',
		color: Colors.disabled.text
	},
	eventContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	eventTitle: {},
	time: {
		fontSize: '11px',
		color: Colors.disabled.text
	}
}

export default withApollo(injectIntl(SelectProgram))
