import moment from 'moment-timezone'
import { find } from 'lodash'

// convert the timezone ISO format to the UTC format
// Used to display the timezone in the timezone switch button
export const convertTimezoneISOToUTCFormat = (timezoneName: string) => {
	if (moment.tz.zone(timezoneName) != null) {
		const Z = moment().tz(timezoneName)
		const offset = parseInt(Z.format('Z'))
		if (offset > 0) {
			return `UTC+${offset}`
		} else if (offset < 0) {
			return `UTC${offset}`
		} else {
			return 'UTC'
		}
	} else {
		return 'Unknown'
	}
}

// WARNING: it is impossible to reliably guess ISO TZ name (e.g. "America/Los_Angeles") from the UTC offset.
// Multiple TZs can share the same UTC offset at a specific point in time, and diverge later (e.g. because of DST).
// This function remains useful as a clutch for working with moment-timezone
const getISOTimezoneFromOffset = (timezoneOffset: number) => {
	const ISOTimezone = find(moment.tz.names(), (timezoneName: string) => {
		return timezoneOffset === parseInt(moment.tz(timezoneName).format('Z'))
	})
	return ISOTimezone
}

// WARNING: it is impossible to reliably guess a TZ's abbreviated name (e.g. "PDT") from the UTC offset.
// Multiple TZs can share the same UTC offset at a specific point in time, and diverge later (e.g. because of DST).
// This function remains useful as a clutch for working with moment-timezone
const getISOTimezoneFromAbbreviation = (abbreviation: string) => {
	return find(moment.tz.names(), (timezoneName: string) => {
		const abbrs = moment.tz.zone(timezoneName)?.abbrs
		return abbrs?.includes(abbreviation)
	})
}

// Take any timezone format (abbreviation, UTC, ISO)
// and return the ISO format
// This method should be used carefully since the returned timezone
// will only be valid to be pass to a date formatter (like moment-timezone or react-intl's FormattedDate)
// to get a formatted localized date
// But the returned timezone is not reliable to be stored and reuse
// because it could lead to error due to the existence of abbreviations with offsets that can vary over the time
export const normalizeTimezoneToISOFormat = (timezone: string) => {
	// If the timezone is in the UTC format then return it as it is
	if (timezone.includes('UTC')) {
		const normalizedTimezone = getISOTimezoneFromOffset(
			parseInt(timezone.replace('UTC', ''))
		)
		if (normalizedTimezone) {
			return normalizedTimezone
		} else {
			return timezone
		}
	}
	// If the timezone is in the ISO format then return it as it is
	else if (moment.tz.zone(timezone) != null) {
		return timezone
	}
	// Then if the timezone is in an other format then convert it to ISO
	else {
		// Get the ISO format if it exists
		const normalizedTimezone = getISOTimezoneFromAbbreviation(timezone)

		if (normalizedTimezone) {
			return normalizedTimezone
		} else {
			return timezone
		}
	}
}

export const timeAgo = (input: string | Date) => {
	const date = input instanceof Date ? input : new Date(input)
	const ranges = [
		['years', 3600 * 24 * 365],
		['months', 3600 * 24 * 30],
		['weeks', 3600 * 24 * 7],
		['days', 3600 * 24],
		['hours', 3600],
		['minutes', 60],
		['seconds', 1]
	] as const
	const secondsElapsed = (date.getTime() - Date.now()) / 1000

	for (const [rangeType, rangeVal] of ranges) {
		if (rangeVal < Math.abs(secondsElapsed)) {
			const delta = Math.ceil(secondsElapsed / rangeVal)
			return { rangeType, delta }
		}
	}

	return {}
}
